import React from "react";

export default function SMEArianeBackgroundIconSvg() {
  return (
    <svg
      width="1282"
      height="290"
      viewBox="0 0 1282 290"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className="w-full absolute -z-[1]  lg:-top-[100px] md:-top-[150px] -top-[150px] left-[0%] right-[0%]"
    >
      <path
        d="M0.0117204 25.5507C0.0117205 11.4394 12.2236 0 27.2877 0H413.483C428.804 0 443.818 4.0297 456.815 11.6303L529.584 54.1847C540.415 60.5185 552.926 63.8767 565.694 63.8767H1047.72H1254.71C1269.77 63.8767 1281.98 75.3161 1281.98 89.4273V263.811C1281.98 277.922 1269.77 289.361 1254.71 289.361H27.2877C12.2236 289.361 0.0117187 277.922 0.0117188 263.811L0.0117204 25.5507Z"
        fill="#f6f6f6"
        className="folder"
      />
    </svg>
  );
}
